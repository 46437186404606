<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-11-21 15:24:33
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-11-22 10:55:23
 * @FilePath: /mediatom-web/src/viewsForManage/Finance/SettleManager/component/InvoiceInfoModal/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <a-modal
    v-model="invoiceVisible"
    @onCancel="handleCancel"
    @ok="handleCancel"
    width="544px"
  >
    <template slot="title">
      <span>发票信息</span>
    </template>
    <a-form-model ref="ruleForm" :model="query" v-bind="layout">
      <!-- 公司名称 -->
      <a-form-model-item label="公司名称" prop="companyName">
        <a-input :value="query.companyName || '--'" disabled/>
      </a-form-model-item>
      <!-- 公司税号 -->
      <a-form-model-item label="公司税号" prop="companyTaxId">
        <a-input :value="query.companyTaxId || '--'" disabled/>
      </a-form-model-item>
      <!-- 注册地址 -->
      <a-form-model-item label="注册地址" prop="registerAddress">
        <a-input :value="query.registerAddress || '--'" disabled/>
      </a-form-model-item>
      <!-- 开户银行 -->
      <a-form-model-item label="开户银行" prop="bank">
        <a-input :value="query.bank || '--'" disabled/>
      </a-form-model-item>
      <!-- 发票类型 -->
      <a-form-model-item label="发票类型" prop="invoiceType">
        <a-input :value="query.invoiceType || '--'" disabled/>
      </a-form-model-item>
      <!-- 联系电话 -->
      <a-form-model-item label="联系电话" prop="contactNumber">
        <a-input :value="query.contactNumber || '--'" disabled/>
      </a-form-model-item>
      <!-- 发票内容 -->
      <a-form-model-item label="发票内容" prop="invoiceContent">
        <a-input :value="query.invoiceContent || '--'" disabled/>
      </a-form-model-item>
      <!-- 邮寄地址 -->
      <a-form-model-item label="邮寄地址" prop="mailAddress">
        <a-input :value="query.mailAddress || '--'" disabled/>
      </a-form-model-item>
      <!-- 收件人/电话 -->
      <a-form-model-item label="收件人/电话" prop="recipientAndNumber">
        <a-input :value="query.recipientAndNumber || '--'" disabled/>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { getInvoiceInfo } from '@/apiForManage/finance/settle'
export default {
  data () {
    return {
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 }
      },
      rules: {},
      hasGet: false,
      query: {}
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    visible: {
      handler (val) {
        if (val && !this.hasGet) {
          this.hasGet = true
          this.getInfo()
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    invoiceVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  methods: {
    handleCancel () {
      this.invoiceVisible = false
    },
    async getInfo () {
      const { data = {} } = await getInvoiceInfo()
      this.query = data
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep{
  .ant-form-item{
    margin-bottom: @smallMargin;
  }
}
</style>
