<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-11-21 15:24:33
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-11-22 14:18:48
 * @FilePath: /mediatom-web/src/viewsForManage/Finance/SettleManager/component/InvoiceInfoModal/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <a-modal
    v-model="updateStsVisible"
    @onCancel="handleCancel"
    @ok="handleSubmit"
    width="544px"
  >
    <template slot="title">
      <span>修改结算状态</span>
    </template>
    <a-form-model ref="ruleForm" :model="query" v-bind="layout">
      <!-- 公司名称 -->
      <a-form-model-item label="公司名称" prop="developerName">
        <a-input :value="query.developerName || '--'" disabled/>
      </a-form-model-item>
      <!-- 账户名称 -->
      <a-form-model-item label="账户名称" prop="userName">
        <a-input :value="query.userName || '--'" disabled/>
      </a-form-model-item>
      <!-- 登录邮箱 -->
      <a-form-model-item label="登录邮箱" prop="loginId">
        <a-input :value="query.loginId || '--'" disabled/>
      </a-form-model-item>
      <!-- 结算金额 -->
      <a-form-model-item label="结算金额" prop="amount">
        <a-input :value="query.amount || '--'" disabled/>
      </a-form-model-item>
      <!-- 结算状态 -->
      <a-form-model-item label="结算状态" prop="sts" :rules="[{ required: true, message: '请选择结算状态', trigger: 'change' }]">
        <a-select
          placeholder="请选择结算状态"
          v-model="query.sts"
        >
          <a-select-option
            v-for="item in stsList"
            :key="item.id"
            :value="item.id"
          >{{ item.name }}</a-select-option>
        </a-select>
      </a-form-model-item>
      <!-- 失败原因 -->
      <a-form-model-item label="失败原因" prop="remark" v-if="query.sts === 6 || query.sts === 3">
        <a-input type="textarea" v-model="query.remark" aria-placeholder="请输入失败原因"/>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { updateSts } from '@/apiForManage/finance/settle'
import { stsList } from '../../list'
export default {
  data () {
    return {
      stsList,
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 }
      },
      rules: {},
      query: {}
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    currentRow: {
      type: Object,
      default: () => ({})
    }
  },
  watch: {
    visible: {
      handler (val) {
        if (val) {
          this.query = { ...this.currentRow }
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    updateStsVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  methods: {
    handleCancel () {
      this.updateStsVisible = false
    },
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          updateSts(this.query).then((res) => {
            if (res.code === 200) {
              this.$message.success('操作成功')
              this.$emit('modalCancel')
              this.$emit('refresh')
            } else {
              this.$message.error(res.msg || '操作失败')
            }
          }).catch((err) => {
            this.$message.error(err.msg || '操作失败')
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
::v-deep{
  .ant-form-item{
    margin-bottom: @smallMargin;
  }
}
</style>
