<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-09-23 17:47:30
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-11-22 14:14:02
 * @FilePath: /mediatom-web/src/viewsForManage/Finance/SettleManager/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="settle-manager-container">
    <div class="search-box">
      <div class="left">
        <a-range-picker
          style="width: 240px"
          class="search_item"
          :mode="['month', 'month']"
          :format="format"
          :placeholder="['开始月份', '结束月份']"
          @panelChange="ChangeDate"
          :value="rangePickerDate"
          :allowClear="true"
          @openChange="handleOpenChange"
          :ranges="{
          }"
        >
        </a-range-picker>
        <m-select
          v-model="query.sts"
          :showSearch="false"
          class="search-item"
          :dataList="stsList"
          label="结算状态"
          @change="handleSearch"
        />
        <a-input v-if="roleId === 4" placeholder="请输入账号或公司名称" v-model="query.name" class="search-item"></a-input>
      </div>
      <div class="right">
        <a-button type="primary" style="width: 116px" v-if="roleId !== 4" @click="invoiceVisible = true">发票信息</a-button>
      </div>
    </div>
    <ADXSettlementNav v-if="roleId !== 4" />
    <div class="main">
      <a-spin :spinning="isLoading">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="date" label="业务时间" min-width="160">
          </el-table-column>

          <el-table-column prop="invoiceName" v-if="roleId !== 4" label="发票客户名称" min-width="160">
          </el-table-column>
          <el-table-column prop="developerName" :label="roleId === 4 ? '公司名称' : '开发者名称'" min-width="160">
          </el-table-column>
          <el-table-column prop="loginId" v-if="roleId === 4" label="登录邮箱" min-width="160">
          </el-table-column>

          <el-table-column prop="amount" label="结算金额" min-width="160">
            <template slot-scope="{ row }">
              <a-button @click="handleGetAmountDetail(row)" type="link">{{ moneyText }}{{ numFormat(row.amount, 3, '') }}</a-button>
            </template>
          </el-table-column>
          <el-table-column prop="sts" label="结算状态" min-width="160">
            <template slot-scope="{ row }">
              <SettleSts :row="row"/>
            </template>
          </el-table-column>
          <el-table-column label="操作" min-width="160">
            <template slot-scope="{ row }">
              <a-button type="link" v-if="roleId !== 4" @click="handleDownload(row)">下载结算单</a-button>
              <a-button type="link" @click="handleUpdateSts(row)" v-else>更改状态</a-button>
            </template>
          </el-table-column>
        </el-table>
        <m-empty v-if="tableData.length === 0" style="height: 341px" imgHeight="176px">
          <template #description> 暂时没有任何数据 </template>
        </m-empty>
        <a-pagination
          class="pagination"
          v-if="tableData.length > 0"
          show-size-changer
          :current="query.page"
          :total="total"
          :show-total="(total) => `共 ${total} 条`"
          @change="handleChangePage"
          @showSizeChange="onShowSizeChange"
          :pageSize="query.limit"
        />
      </a-spin>
    </div>
    <InvoiceInfoModal :visible="invoiceVisible" @modalCancel="invoiceVisible = false"/>
    <AmountDetailModal :visible="detailVisible" @modalCancel="detailVisible = false" :currentRow="currentRow"/>
    <UpdateStsModal @refresh="getDataList" :visible="updateStsVisible" @modalCancel="updateStsVisible = false" :currentRow="currentRow"/>
  </div>
</template>

<script>
import mixDate from '@/mixins/initDate'
import rateAndMoneyMethod from '@/mixins/rateAndMoneyMethod'
import { mapState } from 'vuex'
import { numFormat } from '@/utils/dealNumber'
// import moment from 'moment'
import ADXSettlementNav from './ADXSettlementNav'
// import excel from '@/utils/excel'
import { stsList } from './list'
import { getSettlePage, downloadSettleInfo } from '@/apiForManage/finance/settle'
import InvoiceInfoModal from './component/InvoiceInfoModal'
import SettleSts from './component/SettleSts'
import AmountDetailModal from './component/AmountDetailModal'
import UpdateStsModal from './component/UpdateStsModal'
import excel from '@/utils/excel'

const detailPart = () => import(/* webpackChunkName: "dialog" */ './detailPart')
export default {
  name: 'SettleManager',
  components: { detailPart, ADXSettlementNav, InvoiceInfoModal, SettleSts, AmountDetailModal, UpdateStsModal },
  mixins: [mixDate, rateAndMoneyMethod],
  data () {
    return {
      stsList,
      query: {
        startDate: undefined,
        endDate: undefined,
        sts: undefined,
        name: undefined,
        page: 1,
        limit: 10
      },
      tableData: [],
      total: 0,
      isLoading: false,
      invoiceVisible: false,
      format: 'YYYY-MM',
      detailVisible: false,
      currentRow: {},
      updateStsVisible: false
    }
  },
  computed: {
    ...mapState({
      isAdmin: (state) => state.user.isAdmin,
      role: (state) => state.user.roles[0],
      roleId: (state) => state.user.roleId,
      title: (state) => state.autoweb.title,
      cur: (state) => state.user.cur,
      moneyType: (state) => (+state.user.cur === 1 ? '$' : '¥')
    })
  },
  created () {
    this.handleInitDate()
    this.handleSearch()
  },
  methods: {
    handleUpdateSts (row) {
      this.currentRow = JSON.parse(JSON.stringify(row))
      this.updateStsVisible = true
    },
    handleGetAmountDetail (row) {
      this.currentRow = JSON.parse(JSON.stringify(row))
      this.detailVisible = true
    },
    numFormat,
    // 初始化时间
    handleInitDate () {
      const { endMonth, startMonth, end, start } = this.initMonth(1, 0, this.format)
      this.rangePickerDate = [start, end]
      this.query.endDate = endMonth
      this.query.startDate = startMonth
    },
    handleOpenChange (status) {
      if (!status) {
        this.handleSearch()
      }
    },
    ChangeDate (date) {
      this.query = {
        ...this.query,
        endDate: this.moment(date[1]).format(this.format),
        startDate: this.moment(date[0]).format(this.format)
      }
      this.rangePickerDate = date
      // this.handleSearch()
    },
    handleSearch () {
      this.query.page = 1
      this.getDataList()
    },
    async getDataList () {
      this.isLoading = true
      const { data = {} } = await getSettlePage(this.query)
      const { items = [], total = 0 } = data
      this.tableData = items
      this.total = total
      this.isLoading = false
    },
    handleChangePage (page) {
      this.query.page = page
      this.getDataList()
    },
    onShowSizeChange (current, size) {
      this.query.page = 1
      this.query.limit = size
      this.getDataList()
    },
    handleDownload (row) {
      downloadSettleInfo({ id: row.id }).then((res) => {
        try {
          excel(res, `结算单.pdf`)
        } catch (e) {
          console.log(e, '导出pdf错误信息')
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.settle-manager-container {
  margin: 0 @mediumMargin @mediumMargin @mediumMargin;
  .search-box {
    padding: @smallMargin 0;
    display: flex;
    justify-content: space-between;
    .search-item {
      width: 220px;
      margin-left: @compMargin;
    }
  }
  .main{
    padding: 0 @smallMargin @smallMargin @smallMargin;
    border-radius: @mediumRadius;
    background-color: #fff;
    margin-top: @mediumMargin;
    border: 1px solid @modelBorderColor;
    box-shadow: @modelShadow;
    .pagination{
      margin-top: @smallMargin;
    }
  }
}
</style>
